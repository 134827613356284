<template>
  <div class="main-content">
    <div class="main-content__inner __no-border" v-if="sections">
      <BaseBreadCrumbs/>
      <h2 class="help-center-title">{{ $t('helpCenter') }}</h2>
      <base-select
        class="help-center-search"
        label="title"
        :value="null"
        :options="allTopicsForSelect"
        :placeholder="$t('searchTopicPlaceholder')"
        @input="openTopic"
      />

      <div class="help-center-all-container">
        <TopicList
          class="sections-panel"
          :sections="sections"
          :selectedSectionId="selectedSectionId"
          :selectedTopicId="selectedTopicId"
        />
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBreadCrumbs from '@/components/ui/layout/BaseBreadCrumbs'
import { whiteBackground } from '@/mixins/whiteBackground'
import { helpCenterPage } from '@/mixins/helpCenterPage'
import { mapState } from 'vuex'
import TopicList from '@/components/ui/expansion/TopicList'

export default {
  name: 'HelpCenterAll',
  mixins: [
    whiteBackground,
    helpCenterPage,
  ],
  components: {
    TopicList,
    BaseBreadCrumbs,
  },
  computed: {
    ...mapState({
      section: state => state.helpCenter.section,
      topic: state => state.helpCenter.topic,
    }),
    selectedSectionId () {
      return this?.section?.id ? Number(this?.section?.id) : null
    },
    selectedTopicId () {
      return this?.topic?.id ? Number(this?.topic?.id) : null
    },
  },
}
</script>

<style scoped lang="scss">
.help-center-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.help-center-search {
  margin-bottom: 32px;
}
.help-center-all-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 30px;
}
.sections-panel {
  padding-right: 20px;
  border-right: 1px solid $border-color;
}
</style>
