<template>
  <ExpansionList>
    <template>
      <BaseExpansionPanel
        class="help-center-panel"
        v-for="section in sections"
        :open=" section.id === selectedSectionId"
        :class="{ '__selected':  section.id === selectedSectionId && selectedTopicId === null }"
        :key="section.id"
        :noToggle="true"
        :showIcon="false"
      >
        <template #header>
          <span @click="() => { selectSection(section.id) }">
            {{section.title}}
          </span>
        </template>
        <template #content>
          <ul class="help-center-panel--list">
            <li
              v-for="topic in section.topics"
              :key="topic.id"
            >
              <router-link
                class="help-center-panel-item"
                :class="{ '__selected': topic.id === selectedTopicId }"
                :to="`/help-center-all/topic/${topic.slug}`"
              >
                {{ topic.title }}
              </router-link>
            </li>
          </ul>
        </template>
      </BaseExpansionPanel>
    </template>
  </ExpansionList>
</template>

<script>
import ExpansionList from '@/components/ui/expansion/ExpansionList'
import BaseExpansionPanel from '@/components/ui/expansion/BaseExpansionPanel'
export default {
  name: 'TopicList',
  components: {
    BaseExpansionPanel,
    ExpansionList,
  },
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
    selectedSectionId: Number,
    selectedTopicId: Number,
  },
  methods: {
    selectSection (sectionId) {
      if (sectionId !== Number(this.$route.params.sectionId)) {
        this.$router.push(`/help-center-all/section/${sectionId}`)
      }
    },
    selectTopic (slug) {
      this.$router.push(`/help-center-all/topic/${slug}`)
    },
  },
}
</script>

<style scoped lang="scss">
.help-center-panel {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $text-black;
  transition: 0.3s ease color;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    color: #000;
  }

  &.__selected {
    color: $color-primary;

    &:hover {
      color: $color-primary-hover;
    }
  }

  &--list {
    padding: 20px 0 0 20px;
  }
}
.help-center-panel-item {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 16px;
  color: $text-black;
  text-decoration: none;
  transition: 0.3s ease color;

  &:hover {
    color: #000;
  }

  &.__selected {
    color: $color-primary;

    &:hover {
      color: $color-primary-hover;
    }
  }
}
</style>
